import { useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'
import Loader from "react-js-loader";

import api from '../../../services/api';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle, 
  reactPlayer,
  separatorStyle,
} from './styles';

const VideoPlayer = () => {    
  const [ lesson, setLesson ] = useState<any>(null);

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/intro');         
        if (response.data) {          
          setLesson(response.data.lesson);          
        }        
      } catch (error: any) {
                          
      }     
    }    
    loadData();    
  }, []);

  /*const handleFinishedVideo = async () => {
    try {     
      await api.put('/courses/'+courseLesson.courseUid+'/lessons/'+courseLesson.uid+'/progress');
    } catch (error: any) {
      
    }
  }*/

  return (
    <Box sx={mainStyle}>         
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>VIDEO AULA DE APRESENTAÇÃO</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          {
            lesson ?
              <ReactPlayer
              style={reactPlayer}
              url={lesson.video}
              width='100%'
              height='100%'
              controls
              pip
              light={<img style={imgStyle} src={lesson.thumbnail} alt='Thumbnail' />}
              //onEnded={handleFinishedVideo} 
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }
                }}
              />
              :
              <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
          }
        </Box>                   
      </Box>
      <Box sx={separatorStyle}></Box>
    </Box>
  );
};

export default VideoPlayer;
