import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    lg: '45rem',
  },
  height: {
    xs: '26.87rem',
    sm: '30rem',
    lg: '35.7rem',  
  },      
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',  
};

export const contentStyle: SxProps = {
  width: '100%',
  height: {
    xs: '16.87rem',
    sm: '20rem',
    lg: '25.7rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  cursor: 'pointer',
};

export const titleBoxStyle: SxProps = {
  width: '100%',
  height: '5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))', 
};

export const h1Style: SxProps = {   
  width: '96%',
  fontSize: {
    xs: '1.5rem',
    sm: '1.7rem',       
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
  textAlign: 'center',  
};

export const imgStyle: SxProps = {
  width: '100%',
  height: '100%',
}

export const buttonBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const buttonStyle: SxProps = {
  width: {
    xs: '17rem',
    //sm: '25rem',
    //md: '18rem',
    //xl: '22rem',
  },
  height: {
    xs: '3rem',
    //sm: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',
    //sm: '1.7rem',
    //md: '1.4rem',
    //xl: '1.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-hover)' },    
};

export const buttonPlayStyle: SxProps = {
  width: {
    xs: '17rem',
    //sm: '25rem',
    //md: '18rem',
    //xl: '22rem',
  },
  height: {
    xs: '3rem',
    //sm: '4rem',
  },  
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text-green)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: {
    xs: '1.5rem',
    //sm: '1.7rem',
    //md: '1.4rem',
    //xl: '1.6rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',    
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  '&:hover': { color: 'var(--color-button-text-green-hover)' },    
};

