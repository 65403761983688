import { SxProps } from "@mui/system";

export const mainStyle: SxProps = {
  width: '100%',
  height: {
    xs: '220rem',
    sm: '225rem',    
    lg: '155rem',
  },
  background: 'var(--color-background)',   
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const contentStyle: SxProps = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',  
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '3rem',
    lg: '3.8rem',
  }, 
  fontWeight: 700,
  fontFamily: 'barlow',
  color: 'var(--color-text-brown)',
  marginTop: '2rem',
  marginBottom: '3rem',
};

export const pStyle: SxProps = {
  width: {
    xs: '85%',
    lg: '70%',
  },
  fontSize: {
    xs: '2.3rem',    
    lg: '3.5rem',
  }, 
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
  textAlign: 'center',
  marginTop: '2rem',
  marginBottom: {
    xs: '3rem',
    sm: '4rem',
    lg: '8rem',
  }
};

export const itens1Style: SxProps = {
  width: '100%',
  height: {
    xs: '110.1rem',
    lg: '50rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: 'center',
  alignItems: 'center',  
};

export const itens2Style: SxProps = {
  width: '100%',
  height: {
    xs: '73.4rem',
    lg: '50rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  justifyContent: 'center',
  alignItems: 'center',  
};

export const itemStyle: SxProps = {
  width: {
    xs: '28rem',
    lg: '32rem',
  },
  height: {
    xs: '32.7rem',
    lg: '37.65rem',
  },
  marginTop: '2rem',
  marginBottom: '2rem',
  marginLeft: {
    lg: '2rem',
  },
  marginRight: {
    lg: '2rem',
  },
};

export const imageStyle: SxProps = {
  width: '100%',
  height: '82%',
};

export const titleBoxStyle: SxProps = {
  width: '100%',
  height: '6rem',
  backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const titleStyle: SxProps = {
  textAlign: 'center',
  marginTop: '0',
  fontSize: '2rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
};

export const linkStyle: SxProps = {
  textDecorationColor: 'var(--color-text-brown)',
};
