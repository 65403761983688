import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from "react-js-loader";

import api from '../../../services/api';

import {
  mainStyle,
  titleStyle,
  faqStyle,
  accordionStyle,  
  h3Style,
  h4Style,
  strongStyle,
  pStyle,
} from './styles';

const Faq: React.FC = () => {
  const [ expanded, setExpanded ] = useState<string | false>(false);
  const [ questionsAndAnswers, setQuestionsAndAnswers ] = useState<Array<any>>([]);
  const [ loader, setLoader ] = useState(true);

  useEffect(() => {
    async function handleLoadData() {
      setLoader(true);
      try {       
        let response: any = await api.get('/public/questions-and-answers');      
        if (response.status === 200) { 
          setQuestionsAndAnswers(response.data.questionsAndAnswers);
          setLoader(false);            
        }               
      } catch (error) {
        console.log('error:', error);
        setLoader(false);
      }
    };   
    handleLoadData();    
  }, []);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={mainStyle}>
      <Box sx={titleStyle}> 
        <Box component='h3' sx={h3Style}>(F.A.Q.)</Box>
        <Box component='h3' sx={h3Style}>PERGUNTAS FREQUENTES</Box>
      </Box>      

      <Box sx={faqStyle}>
        {
          loader ?
            <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={80} />
          :
            questionsAndAnswers.length > 0 ?
              questionsAndAnswers.map((q: any) => {
                return (
                  <Accordion
                    key={q.question}
                    expanded={expanded === q.question} 
                    onChange={handleChange(q.question)}         
                    sx={{ ... accordionStyle,
                      '& .MuiAccordion-region': { height: expanded === q.question ? 'auto' : 0 },
                      '& .MuiAccordionDetails-root': { display: expanded === q.question ? 'block' : 'none' },            
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="faq-content"
                      id="faq-header"
                    >
                      <Box component='strong' sx={strongStyle}>{q.question}</Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box component='p' sx={pStyle}>
                        {q.answer}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            :
            <Box component='h4' sx={h4Style}>NADA ENCONTRADO!</Box>
        }
      </Box>
      
    </Box>
  );
}

export default Faq;
