import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,
  position: 'relative',     
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',     
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',  
};

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '45rem',
    md: '65rem',
   lg: '100rem',      
  },
  height: {
    xs: '15rem',
    md: '13rem',    
    lg: '15rem',
  },  
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  justifyContent: {
    xs: 'space-evenly'
  },    
  alignItems: 'center',   
  marginTop: {
    xs: '4rem',
    lg: '6rem',
  },
  marginBottom: {
    xs: '4rem',
    lg: '6rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  borderRadius: '4rem',
};

export const textBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',    
  alignItems: 'center',   
}

export const strongStyle: SxProps = {
  width: {
    xs: '27rem',
    sm: '38rem',
    md: '40rem',
    lg: '50rem',
  },
  fontSize: {
    xs: '1.5rem',
    md: '1.6rem',
    lg: '2rem',
  },
  fontWeight: {
    xs: 600,
    lg: 700,
  },
  fontFamily: 'barlow',
  textAlign: 'center',
  color: 'var(--color-text-blue-signup)',  
}

export const buttonStyle: SxProps = {
  width: {
    xs: '18rem',
    lg: '30rem',  
  },
  height: {
    xs: '4rem',
    lg: '6rem',  
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',
  color: 'var(--color-button-text-light)',
  border: 0,
  borderRadius: '4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '1.5rem',
    lg: '2rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',  
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-light-hover)' },   
};

export const linkStyle: SxProps = {
  textDecoration: 'none'
};
