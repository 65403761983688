import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,      
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center',
};

export const contentStyle: SxProps = {
  width: { xs: '40rem', md:'100rem' },
  height: {
    flexGrow: 1,     
  },
  minHeight: '40rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: '4rem',
  marginTop: '2rem',
};

export const h3Style: SxProps = {
  fontSize: '3rem',
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  textShadow: '2px 2px var(--color-text-dark-gray)',
  marginTop: '3rem',
  marginBottom: '3rem',        
};

export const infoStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    md: '80rem',
    lg: '100%'
  },
  height: {
    xs: '100rem',
    sm: '110rem',
    md: '90rem',
    lg: '50rem',
  },
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',     
  },
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
  marginBottom: {
    xs: '1rem',
    lg: '4rem',
  },    
};

export const imgStyle: SxProps = {
  width: {
    xs: '28rem',
    sm: '33rem',
    md: '40rem',
    lg: '45rem',
  },
  height: {
    xs: '28rem',
    sm: '33rem',
    md: '40rem',
    lg: '45rem',
  },
  marginBottom: {
    xs: '3rem',
    md: '4rem',
    lg: '0',
  },
  marginRight: {
    lg: '2rem',
    xl: '4rem',
  }
};

export const textStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '35rem',
    md: '55rem',
    lg: '60rem',
  },
  height: {
    xs: '65rem',
    sm: '70rem',
    md: '45rem',
  },
  display: 'flex',
  flexDirection: 'column',     
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: {
    lg: '2rem',
    xl: '4rem',
  },    
};

 export const pStyle: SxProps = {   
  fontSize: {
    xs: '1.5rem',
    sm: '1.6rem',
    lg: '1.7rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginBottom: '0.5rem',
  textAlign: 'justify',
};

export const pItemStyle: SxProps = {   
  fontSize: {
    xs: '1.5rem',
    sm: '1.6rem',
    lg: '1.7rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginBottom: '0.5rem',
  marginLeft: {
    xs: '1rem',
    md: '2rem',
  },
  textAlign: 'justify',
};

export const strongStyle: SxProps = {   
  width: '100%',
  fontSize: {
    xs: '1.6rem',
    sm: '1.7rem',
    lg: '1.8rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',   
  textAlign: 'justify',
  marginTop: '1rem',
};
