import Box from '@mui/system/Box';

import {
  mainStyle,
  contentStyle,
  textBoxStyle,
  strongStyle,  
  buttonStyle,
  linkStyle,
} from './styles';

const SignUp = () => {
  return (
    <Box sx={mainStyle}>      
      <Box sx={contentStyle}>
        <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'>
          <Box component='button' sx={buttonStyle}>CADASTRE-SE AQUI!</Box>
        </Box>
        <Box sx={textBoxStyle}>
          <Box component='strong' sx={strongStyle}>TORNE-SE UM ALUNO E COMECE AGORA SUA JORNADA DE TRANSFORMAÇÃO PESSOAL E PROFISSIONAL!</Box>            
        </Box>                         
      </Box>
    </Box>
  );
};

export default SignUp;
