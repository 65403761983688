import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,
  position: 'relative',     
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center',    
};

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '45rem',
    md: '55rem',
    lg: '85rem',      
  },
  height: {
    flexGrow: 1,      
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',    
  alignItems: 'center',   
  marginTop: {
    xs: '5rem',
    lg: '6rem',
  },
  marginBottom: {
    xs: '5rem',
    lg: '6rem',
  },
};

export const thumbnailBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '16.87rem',
    sm: '25.31rem',
    md: '30.93rem',
    lg: '47.81rem',
    //xl: '56.25rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
  marginBottom: {
    xs: '1rem',
  } 
};

export const h1Style: SxProps = { 
  fontSize: {
    xs: '2rem',
    sm: '3rem',
    md: '3.5rem',
    lg: '5rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginBottom: {
    xs: '1rem',
    sm: '1.5rem',
    lg: '2rem',
  },  
};

export const h2Style: SxProps = { 
  fontSize: {
    xs: '1.8rem',
    sm: '2.8rem',
    md: '3rem',
    lg: '4rem'
  },
  fontStyle: 'italic',
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-yellow)',
  marginBottom: {
    xs: '2rem',
    sm: '3rem',
    lg: '4rem',
  },  
};

export const imgStyle: SxProps = {
  width: '100%',
  height: '100%',
};

export const valueBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
    sm: '6rem',
    lg: '10rem',
  },    
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',      
  alignItems: 'center', 
  marginTop: {
    xs: '1.5rem',
  },
  marginBottom: {
    xs: '1rem',
  },
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '2.5rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-yellow)',
  backgroundColor: 'var(--color-background-bundle-value)'
};

export const pStyle: SxProps = {
  width: '100%',
  fontSize: {
    xs: '1.5rem',
    sm: '1.8rem',
    lg: '2rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-yellow)',  
};

export const hookValueStyle: SxProps = {
  fontSize: {
    xs: '2rem',
    sm: '2.2rem',
    md: '3rem',
    lg: '4.5rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-bundle-value-dark)',
  textDecorationLine: 'line-through',
};

export const finalValueStyle: SxProps = {
  fontSize: {
    xs: '3rem',
    sm: '4rem',
    md: '5rem',
    lg: '6rem',
  },
  fontWeight: {
    xs: 500,
    lg: 600,
  },
  fontFamily: 'barlow',
  color: 'var(--color-bundle-value-light)',   
};

export const descriptionStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    sm: '1.8rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginBottom: {
    xs: '1rem',
  },
  marginTop: {
    lg: '1rem',
  },
  whiteSpace: 'break-spaces',
  textAlign: 'justify',    
};

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    md: '42rem',
    lg: '86rem',
  },
  height: '4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4rem',      
};

export const buttonStyle: SxProps = {
  width: {
    xs: '25rem',
    md: '30rem',
  },
  height: {
    xs: '4rem',
    md: '5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-button-bundle), var(--color-button-bundle-light))',
  color: 'var(--color-button-text-light)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '1.6rem',
    md: '1.8rem',
    lg: '2.2rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-light-hover)' },  
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const notReleasedStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
    sm: '6rem',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4rem',
  backgroundImage: 'linear-gradient(to right, var(--color-button-bundle), var(--color-button-bundle-light))',
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '2.2rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
};

export const releasedInStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',  
};