import React from "react";
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import numerologia1 from '../../../assets/images/numerologia1.png';
import numerologia2 from '../../../assets/images/numerologia2.png';
import numerologia3 from '../../../assets/images/numerologia3.png';
import numerologia4 from '../../../assets/images/numerologia4.png';
import numerologia5 from '../../../assets/images/numerologia5.png';

import {
  mainStyle,
  contentStyle,
  strongStyle,
  itens1Style,
  itens2Style,
  itemStyle,
  imageStyle,
  titleBoxStyle,
  titleStyle,
  pStyle,
  linkStyle,  
} from './styles';

const Numerology: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Escola de Numerologia');

  return (
    <Box sx={mainStyle}>
      <Box sx={contentStyle}>
       
        <Box component='strong' sx={strongStyle}>AS 5 GRANDES NUMEROLOGIAS</Box>       
        
        <Box sx={itens1Style}>         
          <Box sx={itemStyle}>
            <Box component='img' sx={imageStyle} src={numerologia1} alt="Numerologia_Pitagorica"/>
            <Box sx={titleBoxStyle}>
              <Box sx={titleStyle}>Numerologia Pitagórica</Box>
            </Box>
          </Box>                   
          <Box sx={itemStyle}>
            <Box component='img' sx={imageStyle} src={numerologia2} alt="Numerologia_Carmatica"/>
            <Box sx={titleBoxStyle}>
            <Box sx={titleStyle}>Numerologia Carmática</Box>
            </Box>
          </Box>          
          <Box sx={itemStyle}>
            <Box component='img' sx={imageStyle} src={numerologia3} alt="Numerologia_Cabalistica"/>
            <Box sx={titleBoxStyle}>
              <Box sx={titleStyle}>Numerologia Cabalística</Box>
              <Box sx={titleStyle}>(OS CICLOS DA VIDA)</Box>
            </Box>
          </Box>          
        </Box>
        <Box sx={itens2Style}>
          <Box sx={itemStyle}>
            <Box component='img' sx={imageStyle} src={numerologia4} alt="Numerologia_Tantrica"/>
            <Box sx={titleBoxStyle}>
              <Box sx={titleStyle}>Numerologia Tântrica</Box>
            </Box>
          </Box>          
          <Box sx={itemStyle}>
            <Box component='img' sx={imageStyle} src={numerologia5} alt="Numerologia_Projetiva"/>
            <Box sx={titleBoxStyle}>
              <Box sx={titleStyle}>Numerologia Projetiva</Box>
            </Box>
          </Box>                  
        </Box>

        <Box component='p' sx={pStyle}>Evolua sua carreira com a orientação de uma autoridade reconhecida com
          mais de 40 anos de experiência e milhares de clientes recorrentes.</Box> 

        <Tooltip 
          title="Agende sua consulta"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
        > 
          <Box component='a' sx={linkStyle} href='https://www.truenumbers.com.br/agende-sua-consulta' target='_blank' rel='noreferrer noopener'
            onClick={()=> gaEventTracker.eventTracker('Visitar agende sua consulta', 'Visitar agende sua consulta')}>
            <Box component='strong' sx={strongStyle}>AGENDE SUA CONSULTA</Box>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  ) 
};

export default Numerology;
