import React from 'react';
import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Footer from '../../components/PagesComponents/Footer';
import Ingrid from '../../components/PagesComponents/Ingrid';
import Numerology from '../../components/PagesComponents/Numerology';
import Header from '../../components/PagesComponents/Header';
import Ebook from '../../components/PagesComponents/Ebook';
import VideoPlayer from '../../components/PagesComponents/VideoPlayer';
import Main from '../../components/PagesComponents/Main';
import About from '../../components/PagesComponents/About';
import SignUp from '../../components/PagesComponents/SignUp';
import OurLife from '../../components/PagesComponents/OurLife';

import './styles.css';

const Landing: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Pagina Principal');

  useEffect(() => {
    gaEventTracker.sendPageView('/', 'Pagina Principal');
  }, []);
  
  return (
    <div id="landing-page" className="container">      
      <Header />
      <Main />
      <VideoPlayer />
      <SignUp />
      <About />
      <OurLife />
      <Numerology />     
      <Ebook />
      <Ingrid />        
      <Footer />
    </div>
  );
}

export default Landing;
