import React, { useRef, useState, useEffect } from "react";
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { TextInput, TextArea } from '../../components/Form';

import Footer from "../../components/PagesComponents/Footer";

import { sendEmailStudyGroup } from '../../services/api';

import logo from '../../assets/images/logos/logo.png';

import './styles.css';

const textAreaStyle: React.CSSProperties = {
  width: '100%',
  height: '15rem',
  borderStyle: 'solid',
  background: '#E0E0E0',
  padding: '1rem',
  borderRadius: '0.3rem',
  borderColor: '#bebebe',
}
const inputStyle: React.CSSProperties = {
  width: '100%',
  height: '4rem',  
  background: '#E0E0E0',
  marginBottom: '1.5rem',
  borderStyle: 'solid',
  borderColor: '#bebebe',
  borderRadius: '0.3rem',
  padding: '1rem',
  borderWidth: 'thin'
}
interface FormData {
  name: string
  email: string
  whatsapp: string
  message: string,
}

const StudyGroupForm = () => {
  const formRef = useRef<FormHandles>(null);

  const [loader, setLoader] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Formulario Grupo de Estudos');

  useEffect(() => {
    gaEventTracker.sendPageView('/grupo-de-estudos', 'Formulario Grupo de Estudos');
  }, []);

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({
        name: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Seu nome é obrigatório'),          
        email: Yup.string()
          .email('Digite um email válido')
          .required('Campo email é obrigatório'),
        whatsapp: Yup.string().min(3, 'Mínimo de 3 caracteres').required('Campo whatsapp é obrigatório'),
        message: Yup.string().min(3,'Mínimo de 3 caracteres').required('Campo mensagem é obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      await sendEmailStudyGroup(data.name, data.email, data.whatsapp, data.message);  
      formRef.current?.setErrors({}); 
      reset();
      setLoader(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  }

  return (
    <div id="study-group-form-page" className="container">
      <div className="study-group-form">
        <div className="study-group-form-content">
          {
            loader &&
            <div id="loader">
              <Loader type="spinner-circle" bgColor={"#51517B"} color={'#FFFFFF'} size={100} />
            </div> 
          }  
          <strong>GRUPO DE ESTUDOS</strong>
          <div className="form-box">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <p>Nome</p>
              <TextInput name="name" placeholder="Seu nome" type='text' styles={inputStyle} />
              <p>Email</p>
              <TextInput name="email" placeholder="Seu email" type='email' styles={inputStyle}/>
              <p>Whatsapp</p>
              <TextInput name="whatsapp" placeholder="Seu telefone" styles={inputStyle}/>
              <p>Mensagem</p>
              <TextArea name="message" placeholder="Sua mensagem" maxLength={600} styles={textAreaStyle} />
              <div className="button-box">
                <button type="submit">ENVIAR</button>
              </div>              
            </Form>
            <img src={logo} alt="logo"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>    
  );
};

export default StudyGroupForm;
