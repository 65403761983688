import React, { useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Loader from "react-js-loader";

import api from '../../../services/api';

import ICourse from '../../../data/interfaces/ICourse';

import Course from './Course';

import fotoIngrid from '../../../assets/images/ingrid_cursos.png';

import {
  mainStyle, 
  h3Style,
  contentStyle,  
  infoStyle,
  imgStyle,
  pStyle, 
  textStyle,
} from './styles';

const CoursesList: React.FC = () => {
  const [ courses, setCourses ] = useState<Array<any>|[]>([]);
  
  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/courses');        
        if (response.data) {          
          setCourses(response.data.courses);         
        }            
      } catch (error: any) {       
                            
      }   
    }    
    loadData();    
  }, []);  

  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>CURSOS PARA INICIANTES</Box>  
      <Box sx={infoStyle}>
        <Box component='img' sx={imgStyle} src={fotoIngrid} alt='foto_ingrid'/>
        <Box sx={textStyle}>
          <Box component='p' sx={pStyle}>Descubra o caminho para o sucesso pessoal e profissional através do poder 
            transformador da numerologia. Na Escola de Numerologia, oferecemos cursos especializados em Numerologia 
            Pitagórica e Numerologia Carmática, ministrados pela renomada Numeróloga Ingrid Dalila Engel.</Box>
          <Box component='p' sx={pStyle}>Ao se inscrever em nossos cursos, você terá a oportunidade de mergulhar 
            nas profundezas do conhecimento numerológico, desvendando os segredos por trás dos números e aprendendo 
            a aplicar essas poderosas ferramentas em sua vida diária.</Box>
          <Box component='p' sx={pStyle}>Nossos programas de estudo abrangem uma variedade de tópicos, desde os 
            fundamentos básicos até técnicas mais avançadas de interpretação numérica. Você será capacitado a 
            compreender as influências cósmicas em sua vida e a utilizar a numerologia para alcançar seus objetivos 
            pessoais e profissionais.</Box>
          <Box component='p' sx={pStyle}>Não espere mais para dar o próximo passo em direção ao seu sucesso. Junte-se a nós na Escola de 
            Numerologia e liberte o seu potencial ilimitado através do poder dos números.</Box>
          <Box component='p' sx={pStyle}>Adquira já o seu e comece sua jornada rumo à realização pessoal e ao 
            crescimento espiritual.</Box>
        </Box>        
      </Box>
      <Box sx={contentStyle}>
      {
          courses.length > 0 ? 
          <Grid container spacing={{ xs: '2rem', lg: '4rem' }} justifyContent='center' alignItems='center'>
            {courses.map((c: ICourse) => {             
              return (                
                <Grid  xs='auto' md='auto' lg='auto'>                  
                  <Course course={c}/>                               
                </Grid>
              )
            })}
          </Grid> : 
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />    
        }  
      </Box>
    </Box>
  );
}

export default CoursesList;
