import React from "react";
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import logo from '../../../assets/images/logos/logo.png';
import jaime from '../../../assets/images/fotos/jaime.png';
import ingrid from '../../../assets/images/fotos/ingrid.png';
import account from '../../../assets/images/account.png';

import {
  mainStyle,
  contentStyle,
  logoStyle,
  photosBoxStyle,
  infoBoxStyle,
  ingridStyle,
  jaimeStyle,
  textBoxStyle,
  strongStyle,
  pStyle,
  buttonStyle,
  linkStyle,
  linkStyleDecoration,
  accountStyle,
} from './styles';

const Header: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Escola de Numerologia');

  return (
    <Box component='header' sx={mainStyle}>
      <Box sx={contentStyle}>       
        <Box component='img' sx={logoStyle} src={logo} alt="logo_truenumbers"/>
        
        <Tooltip 
          title="Acessar a Área do Aluno"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
        > 
          <Box component='a' sx={linkStyle} href='https://aluno.escoladenumerologia.com.br' target='_blank' rel='noreferrer noopener'
            onClick={()=> gaEventTracker.eventTracker('Visitar minha conta', 'Visitar minha conta')}>
            <Box component='img' sx={accountStyle} src={account} alt="minha_conta"/>
          </Box>
        </Tooltip>
        <Box sx={photosBoxStyle} className="fotos">
          <Box component='img' sx={ingridStyle} src={ingrid} alt="Ingrid"/>
          <Box component='img' sx={jaimeStyle} src={jaime} alt="Jaime"/>
        </Box>
        <Box sx={textBoxStyle}>
          <Box sx={infoBoxStyle}>
            <Tooltip 
              title="Quem somos?"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
            > 
              <Box component='a' sx={linkStyleDecoration} href='https://truenumbers.com.br/quem-somos' target='_blank' rel='noreferrer noopener'
                onClick={()=> gaEventTracker.eventTracker('Visitar quem somos', 'Visitar quem somos')}>
                <Box component='strong' sx={strongStyle}>INGRID DALILA ENGEL</Box>
              </Box>
            </Tooltip>
            <Box component='p' sx={pStyle}>Numeróloga, Psicóloga, Coach</Box>
          </Box>
          <Box sx={infoBoxStyle}>
            <Tooltip 
              title="Quem somos?"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
            > 
              <Box component='a' sx={linkStyleDecoration} href='https://truenumbers.com.br/quem-somos' target='_blank' rel='noreferrer noopener'
                onClick={()=> gaEventTracker.eventTracker('Visitar quem somos', 'Visitar quem somos')}>
                <Box component='strong' sx={strongStyle}>JAIME BENEDETTI</Box>
              </Box>
            </Tooltip>
            <Box component='p' sx={pStyle}>Escritor, Publisher, Life Coach</Box>
          </Box>
        </Box>
        <Tooltip 
          title="Agende sua consulta"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
        > 
          <Box component='a' sx={linkStyle} href='https://www.truenumbers.com.br/agende-sua-consulta' target='_blank' rel='noreferrer noopener'
            onClick={()=> gaEventTracker.eventTracker('Visitar agende sua consulta', 'Visitar agende sua consulta')}>
            <Box component='button' sx={buttonStyle}>AGENDE SUA CONSULTA</Box>
          </Box>
        </Tooltip>        
      </Box>
    </Box>
  );
};

export default Header;
