import { format } from 'date-fns';

const DateUtils = () => {

  const handleDate = (data: any) => {
    try {
      const updatedAt: any = data;    
      return format(updatedAt, 'dd/MM/yyyy');
    } catch (error) {

    }   
  };  
  
  return {
    handleDate,   
  };
};

export default DateUtils;
