import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import fotoIngrid from '../../../assets/images/fotos/ingrid_page.png';
import assinatura from '../../../assets/images/assinatura.png';
import seta from '../../../assets/images/seta.png';

import './styles.css';

const Ingrid: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Escola de Numerologia');

  return (
    <div className="ingrid">
      <div className="ingrid-content">       
        <div className="fundo">
          <div className="ingrid-main">
            <h2>APRENDA NUMEROLOGIA COM
              QUEM ENTENDE PROFUNDAMENTE DISSO</h2>
            <div className="block">
              <div className="image">
                <img src={fotoIngrid} alt="foto_ingrid" />
                <img id="seta" src={seta} alt="seta_para_conheca_ingrid" />
              </div>
              <div className="text">
                <p>"O meu propósito, através da consciência e
                  do conhecimento é facilitar um
                  caminho dentro do propósito maior, na
                  verdade e mais feliz."</p>
                <p id='com-carinho'>Com carinho,</p>
                <img src={assinatura} alt="Ingrid Dalila Engel" />                              
              </div>
            </div>
            <div className='appointment'>
              <Tooltip 
                title="Agende sua consulta"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                slotProps={{ tooltip: { sx: { fontSize: '1.5rem' } } }}
              > 
                <a href='https://www.truenumbers.com.br/agende-sua-consulta' target='_blank' rel='noreferrer noopener'
                  onClick={()=> gaEventTracker.eventTracker('Visitar agende sua consulta', 'Visitar agende sua consulta')}>
                  <h2>AGENDE SUA CONSULTA</h2>
                </a>
              </Tooltip>
            </div>
          </div>
        </div>       
      </div>
    </div>
  );
}
  
export default Ingrid;
  