import React from 'react';

import './styles.css';

const ErrorPage: React.FC = () => {
  return (
    <div id="error-page" className="container">
      <div className='error-container'>
        <h2>Error 404</h2>
        <h3>Página não encontrada</h3>  
      </div>          
    </div>
  );
}

export default ErrorPage;
