import React, { useEffect, useState } from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Loader from "react-js-loader";

import api from '../../../services/api';

import ILesson from '../../../data/interfaces/ILesson';

import Lesson from './Lesson';

import fotoIngrid from '../../../assets/images/ingrid_mentorias.png';

import {
  mainStyle,
  h3Style,
  contentStyle,
  infoStyle,
  imgStyle,
  pStyle,
  pItemStyle,
  strongStyle,
  textStyle,
} from './styles';

const LessonsList: React.FC = () => {  
  const [ lessons, setLessons ] = useState<Array<any>|[]>([]);
  
  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/lessons');        
        if (response.data) {          
          setLessons(response.data.lessons);         
        }            
      } catch (error: any) {       
                            
      }   
    }    
    loadData();    
  }, []);  

  return (
    <Box sx={mainStyle}>
      <Box component='h3' sx={h3Style}>MENTORIAS</Box>
      <Box sx={infoStyle}>
        <Box component='img' sx={imgStyle} src={fotoIngrid} alt='foto_ingrid'/>
        <Box sx={textStyle}>
          <Box component='p' sx={pStyle}>As mentorias da Escola de Numerologia são programas intensivos de 
            orientação que combinam teoria e prática para proporcionar uma compreensão completa e aprofundada 
            da numerologia. Elas são projetadas para atender tanto iniciantes quanto profissionais que desejam 
            expandir seus conhecimentos e habilidades na área.</Box>
          <Box component='strong' sx={strongStyle}>O Que Você Vai Encontrar nas Mentorias</Box>
          <Box component='p' sx={pItemStyle}>• Conteúdo Abrangente: Abordamos desde os fundamentos básicos até temas avançados, incluindo numerologia pitagórica, cabalística, cármica, projetiva e tântrica, 
            além de numerologia empresarial, Numerologia e os Arcanos maiores do Tarô, energia dos nomes, 
            sinastrias e muito mais.</Box>
          <Box component='p' sx={pItemStyle}>• Aulas Gravadas: As aulas, com duração de 2 a 3 horas, são gravadas e disponibilizadas online, 
            permitindo que você aprenda no seu próprio ritmo.</Box>
          <Box component='p' sx={pItemStyle}>• Recursos Adicionais: Ao adquirir nossas mentorias, você terá acesso gratuito ao Manual de Numerologia, 
            um Programa de Cálculo do Mapa Numerológico e outras ferramentas valiosas.</Box>
          <Box component='strong' sx={strongStyle}>Benefícios das Mentorias</Box>
          <Box component='p' sx={pStyle}>Participar das nossas mentorias oferece inúmeros benefícios, como o aprofundamento do 
            conhecimento em numerologia, certificação profissional, autoconhecimento, desenvolvimento de empatia amorosa, 
            e a aplicação prática da numerologia para melhorar relacionamentos e carreiras.</Box>
        </Box>        
      </Box>             
      <Box sx={contentStyle}>        
        {
          lessons.length > 0 ? 
          <Grid container spacing={{ xs: '2rem', lg: '4rem' }} justifyContent='center' alignItems='center'>
            {lessons.map((l: ILesson) => {             
              return (                
                <Grid  xs='auto' md='auto' lg='auto' key={l.uid}>                  
                  <Lesson lesson={l} key={l.uid}/>                               
                </Grid>
              )
            })}
          </Grid> : 
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />    
        }        
      </Box>      
    </Box>
  );
};

export default LessonsList;
