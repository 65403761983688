import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import Header from '../../components/PagesComponents/Header';
import Footer from '../../components/PagesComponents/Footer';
import BundleInfo from '../../components/PagesComponents/BundleInfo';

import api from '../../services/api';

import IBundle from '../../data/interfaces/IBundle';

import { mainStyle } from './styles';

const BundlePage = () => {
  const [ bundle, setBundle ] = useState<IBundle|null>(null);  
  
  const bundleUid = 'bb691177-48af-4636-aa26-0ad31739bc3a';

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/bundles/'+bundleUid);
        console.log(response)      
        if (response.data) {          
          setBundle(response.data.bundle);
        }
              
      } catch (error: any) {
                           
      }         
    }    
    loadData();
    console.log(bundle);   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <Header />      
      {
        bundle ?          
          <BundleInfo bundle={bundle}/>
        :  
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />        
      }
      <Footer />
    </Box>
  );
}

export default BundlePage;
