import { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string,
  label?: string,
  placeholder?: string,
  type?: string,
  styles?: React.CSSProperties,
}

type InputProps = Props
const Input = ({ name, placeholder, type, styles }: InputProps) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  
  return (   
    <input ref={inputRef} placeholder={error ? error : placeholder} type={type} style={styles} />
  );
}

export default Input;
