import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Header from '../../components/PagesComponents/Header';
import LessonsList from '../../components/PagesComponents/LessonsList';
import Footer from '../../components/PagesComponents/Footer';

import { mainStyle } from './styles';

const LessonsPage: React.FC = () => {

  const gaEventTracker = useAnalyticsEventTracker('Pagina Mentorias');

  useEffect(() => {
    gaEventTracker.sendPageView('/mentorias', 'Pagina Mentorias');
  }, []);

  return (
    <Box sx={mainStyle}>     
      <Header />
      <LessonsList />
      <Footer />
    </Box>
  );
}

export default LessonsPage;
