import { SxProps } from "@mui/system";

export const mainStyle: SxProps = {
  width: '100%',
  height: {
    xs: '60rem',
    sm: '68rem',
    md: '65rem',
    lg: '73rem',
  },  
  /*backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',*/
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const contentStyle: SxProps = {
  width: {
    xs: '95%',
    sm: '96%',     
    md: '97%',
    lg: '98%',
  },
  height: {
    xs: '95%',      
    md: '96%',      
  },
  margin: '0 auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',    
  borderStyle: 'solid',
  borderColor: 'var(--color-background)',
};

export const logoStyle: SxProps = {
  width: {
    xs: '40rem',
    md: '58.45rem',
    lg: '83.5rem',
    xl: '91.85rem',
  },
  height: {
    xs: '23.95rem',
    md: '35rem',
    lg: '50rem',
    xl: '55rem'
  },  
};

export const photosBoxStyle: SxProps = {
  width: {
    xs: '87%',
    sm: '85%',
    lg: '90%',      
  },
  height: {
    xs: '16rem',
    lg: '30rem',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',   
  marginTop: {
    xs: 0,
    sm: '4rem',
    md: 0,     
  },
  position: {
    md: 'absolute',
  },
  top: {
    md: '50%',
  },
  left: {
    md: '50%',
  },
  transform: {
    md: 'translate(-50%, 23%)',
    lg: 'translate(-48%, -30%)',
  },
  zIndex: {
    md: 0,
  },     
};

export const ingridStyle: SxProps = {
  width: {
    xs: '14.62rem', //1.16
    sm: '19.82rem',
    md: '25rem',
    lg: '31.03rem',
    xl: '37.06rem',
  },
  height: {
    xs: '17rem',
    sm: '23rem',
    md: '29rem',
    lg: '36rem',
    xl: '43rem',
  }, 
};

export const jaimeStyle: SxProps = {
  width: {
    xs: '14.62rem',
    sm: '19.82rem',
    md: '25rem',
    lg: '31.03rem',
    xl: '37.06rem',
  },
  height: {
    xs: '17rem',
    sm: '23rem',
    md: '29rem',
    lg: '36rem',
    xl: '43rem',
  },
  marginRight: {
    lg: '3rem',
    xl: '5rem'
  }
};

export const textBoxStyle: SxProps = {
  width: '92%',
  height: {
    xs: '7rem',
    sm: '10rem',
    md: '11rem',
    lg: '13rem',
  },
  background: 'var(--color-background)',
  borderRadius: {
    xs: '2rem',
    sm: '4rem',
  },
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderStyle: 'solid',
  borderColor: 'var(--color-background-light)',
  position: {
    md: 'absolute',
  },
  top: {
    md: '50%',
  },
  left: {
    md: '50%',
  },
  transform: {
    md: 'translate(-50%, 175%)',
    lg: 'translate(-50%, 160%)',
  },
  zIndex: {
    md: 1,
  },  
};

export const infoBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: { 
    xs: '1.5rem',
    sm: '3.5rem',
    md: '5.5rem',
    xl: '11rem',
  },
  marginRight: {
    xs: '1.5rem',
    sm: '4.2rem',
    md: '6.5rem',
    xl: '11rem',
  },
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.3rem',
    sm: '1.8rem',
    lg: '2.4rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
};

export const pStyle: SxProps = {
  fontSize: { 
    xs: '1rem',
    sm: '1.3rem',
    lg: '2rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
  marginTop: {
    xs: 0,
    sm: '0.3rem',
  },
};

export const linkStyle: SxProps = {
  textDecoration: 'none'
};

export const linkStyleDecoration: SxProps = {
  textDecorationColor: 'var(--color-text-blue)',
};

export const buttonStyle: SxProps = {
  width: {
    xs: '20rem',
    lg: '25rem',
  },
  height: {
    xs: '4rem',
    lg: '6rem',
  },
  background: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',
  color: 'var(--color-button-text-light)',
  border: 0,
  borderRadius: '0.3rem',
  cursor: 'pointer',
  fontSize: '1.5rem',
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',
  marginTop: {
    xs: '3rem',
    md: 0,
  },
  position: {
    md: 'absolute',
  },
  top: {
    md: '50%',
  },
  left: {
    md: '50%',
  },
  transform: {
    md: 'translate(-50%, 570%)',
    lg: 'translate(-50%, 405%)',
  },
  zIndex: {
    md: 2,
  },  
  '&:hover': { color: 'var(--color-button-text-light-hover)' }, 
};

export const accountStyle: SxProps = {
  width: {
    xs: '12.15rem',
    sm: '15rem',
    md: '20rem',
    lg: '25rem',
  },
  height: {
    xs: '3rem',
    sm: '3.7rem',
    md: '4.93rem',
    lg: '6.17rem',
  },
  position: 'absolute',
  top: {
    xs: '5%',
  },
  right: {
    xs: '5%',
  },
  cursor: 'pointer',
};
