import React from 'react';
import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';
import ContactMe from './pages/ContactMe';
import StudyGroupForm from './pages/StudyGroupForm';
import UnsubscribeForm from './pages/UnsubscribeForm';
import CoursesPage from './pages/CoursesPage';
import LessonsPage from './pages/LessonsPage';
import LessonPage from './pages/LessonPage';
import CoursePage from './pages/CoursePage';
import BundlePage from './pages/BundlePage';
import FaqPage from './pages/FaqPage';

const publicRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/fale-conosco',
        element: <ContactMe />
      },
      {
        path: '/grupo-de-estudos',
        element: <StudyGroupForm />
      },
      {
        path: '/cancelar-inscricao',
        element: <UnsubscribeForm />
      },
      {
        path: '/cursos',
        element: <CoursesPage />
      },
      {
        path: '/mentorias',
        element: <LessonsPage />
      },
      {
        path: '/mentorias/:lessonUid',
        element: <LessonPage />
      },
      {
        path: '/cursos/:courseUid',
        element: <CoursePage />
      },
      {
        path: '/super-curso',
        element: <BundlePage />
      },
      {
        path: '/perguntas-mais-frequentes',
        element: <FaqPage />,
      },
    ],
  },  
]);

const Routes: React.FC = () => {
  return (
    <RouterProvider router={publicRoutes} />
  );
}

export default Routes;
