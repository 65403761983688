import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import Header from '../../components/PagesComponents/Header';
import Footer from '../../components/PagesComponents/Footer';
import LessonVideoPlayer from '../../components/PagesComponents/LessonVideoPlayer';
import LessonInfo from '../../components/PagesComponents/LessonInfo';

import api from '../../services/api';

import ILesson from '../../data/interfaces/ILesson';

import { mainStyle } from './styles';

const LessonPage = () => {
  const [ lesson, setLesson ] = useState<ILesson|null>(null);  
  
  let { lessonUid } = useParams();  

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/lessons/'+lessonUid);
        console.log(response)      
        if (response.data) {          
          setLesson(response.data.lesson);
        }
              
      } catch (error: any) {
                           
      }         
    }    
    loadData();   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <Header />      
      {
        lesson ?
          lesson.value > 0 ?
            <LessonInfo lesson={lesson}/>
          :
            <LessonVideoPlayer lesson={lesson}/>          
        :
        <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />        
      }
      <Footer />
    </Box>
  );
}

export default LessonPage;
