import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,
  position: 'relative',     
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center',    
};

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '45rem',
    md: '55rem',
    lg: '85rem',      
  },
  height: {
    flexGrow: 1,      
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',    
  alignItems: 'center',   
  marginTop: {
    xs: '5rem',
    lg: '6rem',
  },
  marginBottom: {
    xs: '5rem',
    lg: '6rem',
  },
};

export const titleBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
};

export const thumbnailBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '16.87rem',
    sm: '25.31rem',
    md: '30.93rem',
    lg: '47.81rem',
    //xl: '56.25rem',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',    
};

export const h1Style: SxProps = { 
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '2.2rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',   
};

export const imgStyle: SxProps = {
  width: '100%',
  height: '100%',
};

export const infoBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '3rem',
  },    
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',      
  alignItems: 'center',
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',  
};

export const descriptionStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    sm: '1.8rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginTop: '2rem',
  whiteSpace: 'break-spaces',
  textAlign: 'justify',    
};

export const valueStyle: SxProps = {
  fontSize: {
    xs: '1.6rem',
    md: '2.0rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow',
  textDecoration: 'underline var(--color-text-value)',
  color: 'var(--color-text-value)',    
}

export const buttonBoxStyle: SxProps = {
  width: {
    xs: '32rem',
    md: '42rem',
    lg: '86rem',
  },
  height: '4rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4rem',      
};

export const buttonStyle: SxProps = {
  width: {
    xs: '25rem',
    md: '30rem',
  },
  height: {
    xs: '4rem',
    md: '5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '1.5rem',
    md: '1.8rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-hover)' },  
};

export const buttonPagBankStyle: SxProps = {
  width: {
    xs: '25rem',
    md: '30rem',
  },
  height: {
    xs: '4rem',
    md: '5rem',
  },
  background: 'var(--color-button-white)',
  color: 'var(--color-button-text)',
  border: 0,
  borderRadius: '0.4rem',  
  cursor: 'pointer',  
  fontSize: {
    xs: '1.5rem',
    md: '1.8rem',
  },
  fontWeight: 600,
  fontFamily: 'barlow', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  transition: '0.2s',  
  '&:hover': { color: 'var(--color-button-text-hover)' },  
};

export const linkStyle: SxProps = {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const modelStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { 
    xs: '28rem',     
  },
  height: {
    xs: '25rem',      
  },    
  background: 'var(--color-background)',
  border: '2px solid #000',
  borderRadius: '2rem',
  borderColor: '#AC6633',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',   
};

export const modalCloseIcon: SxProps = {
  position: 'absolute' as 'absolute',
  top: {
    xs: '1rem',     
  },
  right: {
    xs: '1rem',     
  },   
  width: '2rem',
  height: '2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'var(--color-background-icon)',
  cursor: 'pointer',
};

export const modalMessageStyle: SxProps = {
  fontSize: {
    xs: '2.2rem',   
  },
  fontWeight: 600,
  fontFamily: 'barlow',  
  color: 'var(--color-text-blue)',
  textAlign: 'center', 
};

export const notReleasedStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
    sm: '6rem',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4rem',
  backgroundImage: 'linear-gradient(to right, var(--color-background-login-dark), var(--color-background-login-light))',
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '2.2rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',
};

export const releasedInStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-blue)',  
};