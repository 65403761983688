import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category: string) => {
  
  const eventTracker = (action: string, label: string) => {
    ReactGA.event({category, action, label});
  };

  const sendPageView = (page: string, title: string) => {
    ReactGA.send({
      hitType: "pageview",
      page,
      title
    });    
  };
  
  return { 
    eventTracker,
    sendPageView,
  };
}

export default useAnalyticsEventTracker;
