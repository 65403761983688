import { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string,
  label?: string,  
  styles?: React.CSSProperties,
  options: Array<string>,  
}

type InputProps = Props
const Select = ({ name, styles, options }: InputProps) => {
  const selectRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  
  return (   
    <select ref={selectRef} style={styles}>
      {options.map((value) => {
        return (
          <option value={value} key={value}>{value}</option>
        )
      })}
    </select>
  );
}

export default Select;
