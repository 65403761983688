import { SxProps } from '@mui/system';

export const mainStyle: SxProps = {
  flexGrow: 1,
  position: 'relative',     
  display: 'flex',
  flexDirection: 'column',      
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, var(--color-background-dark), var(--color-background-light))',   
};

export const contentStyle: SxProps = {
  width: {
    xs: '30rem',
    sm: '45rem',
    md: '55rem',
    lg: '85rem',      
  },
  height: {
    flexGrow: 1,      
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',    
  alignItems: 'center',   
  marginTop: {
    xs: '5rem',
    lg: '6rem',
  },
  marginBottom: {
    xs: '5rem',
    lg: '6rem',
  },
};

export const titleBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '5rem',
    lg: '10rem',
  },  
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',
};

export const videoBoxStyle: SxProps = {
  position: 'relative',
  paddingTop: '56.25%',
  width: '100%',
  height: {
    xs: '16.87rem',
    sm: '25.31rem',
    md: '30.93rem',
    lg: '47.81rem',    
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',      
  alignItems: 'center',    
};

export const h1Style: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
    lg: '3rem'
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',   
};

export const imgStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export const infoBoxStyle: SxProps = {
  width: '100%',
  height: {
    xs: '3rem',
  },    
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',      
  alignItems: 'center',
};

export const strongStyle: SxProps = {
  fontSize: {
    xs: '1.5rem',
    sm: '2.0rem',
  },
  fontWeight: 500,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',  
};

export const descriptionStyle: SxProps = {
  fontSize: {
    xs: '1.4rem',
    sm: '1.8rem',
  },
  fontWeight: 400,
  fontFamily: 'barlow',
  color: 'var(--color-text-white)',
  marginTop: '2rem',
  whiteSpace: 'break-spaces',
  textAlign: 'justify',    
};

export const reactPlayer: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,  
};

export const separatorStyle: SxProps = {
  width: '100%',
  height: {
    xs: '0.5rem',
  },
  backgroundImage: 'linear-gradient(to right, var(--color-background-light), var(--color-background-login-dark))',  
};
