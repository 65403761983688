import Box from '@mui/system/Box';

import IBundle from '../../../data/interfaces/IBundle';

import DateUtils from '../../../utils/dateUtils';

import {
  mainStyle,
  contentStyle,  
  h1Style,
  h2Style,
  thumbnailBoxStyle,
  imgStyle,
  valueBoxStyle,
  strongStyle,
  pStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle, 
  linkStyle,  
  notReleasedStyle,
  releasedInStyle,
  hookValueStyle,
  finalValueStyle,
} from './styles';

interface Props { 
  bundle: IBundle;  
}
type props = Props

const ESCOLAL_URL = 'https://aluno.escoladenumerologia.com.br/super-curso/';

const BundleInfo = ({ bundle }: props) => {
  const dateUtils = DateUtils(); 

  return (
    <Box sx={mainStyle}>           
      <Box sx={contentStyle}>
        
        <Box component='h1' sx={h1Style}>{bundle.title}</Box>
        <Box component='h2' sx={h2Style}>Pitagórica e Carmática</Box>
       
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={bundle.thumbnail} alt='thumbnail'/>
        </Box>        
        <Box component='p' sx={descriptionStyle}>Transforme sua vida com o Super Curso de Numerologia Pitagórica e 
          Carmática! Esta formação completa vai além do básico, oferecendo uma imersão profunda nos mistérios dos 
          números e sua conexão com a evolução espiritual. Aprenda sobre as 81 posturas da evolução divina e 
          explore os números cármicos e mestres que influenciam seu destino.</Box>
       
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}>O QUE VOCÊ VAI RECEBER:</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Curso completo de Numerologia Pitagórica</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Curso completo de Numerologia Carmática</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Programa de Cálculo do Mapa Numerológico</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Manual de Numerologia</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''A BASE DO CONHECIMENTO NUMEROLÓGICO''</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''NUMEROLOGIA PITAGÓRICA''</Box>
        <Box component='p' sx={pStyle} style={{textAlign: 'start', marginBottom: '1rem'}}> * Aula de Mentoria ''NUMEROLOGIA CARMÁTICA''</Box>

        <Box component='p' sx={descriptionStyle}>Com acesso a mentorias exclusivas, o curso combina teoria e prática 
          para mudar sua percepção de si mesmo e do mundo. E mais: aproveite condições especiais e descontos 
          imperdíveis! Não perca a chance de mergulhar no autoconhecimento e na sabedoria dos números.</Box>

        <Box sx={valueBoxStyle}>
          <Box component='h1' sx={hookValueStyle}>R$ 1900</Box>
          <Box component='h1' sx={finalValueStyle}>R$ 950,00</Box>
        </Box>

        <Box component='strong' sx={strongStyle}>à vista ou até 12x nos cartões</Box>

        {
          !bundle.isReleased ?              
            <Box sx={notReleasedStyle}>
              <Box component='strong' sx={releasedInStyle}>DISPONÍVEL EM {dateUtils.handleDate(bundle.releaseDate)}</Box>              
            </Box>      
          :
            <Box sx={buttonBoxStyle}>
              <Box component='a' sx={linkStyle} href={ESCOLAL_URL+bundle.uid} target='_blank' rel='noreferrer noopener'>
                <Box component='button' sx={buttonStyle} type='button'>COMPRAR AGORA</Box>
              </Box>               
            </Box>
        }
      </Box>
    </Box>
  );
};

export default BundleInfo;
