import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import ebook from '../../../assets/images/ebook.png';

import './styles.css';

const Ebook: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Escola de Numerologia');

  return (
    <div className="ebook">
      <div className="ebook-content">
        <strong>MANUAL PRÁTICO E COMPLETO DE NUMEROLOGIA</strong>
        <div className="ebook-main">
          <div className="ebook-text">           
            <p>APRENDA SOBRE UMA DAS
              TÉCNICAS MAIS
              ANTIGAS DO AUTOCONHECIMENTO.
              VOCÊ VAI DESCOBRIR COMO É
              POSSÍVEL APLICAR OS
              CONHECIMENTOS DA NUMEROLOGIA
              NO SEU DIA A DIA.</p>
            <Tooltip 
              title="Se desejar adquirir o Manual de Numerologia em Formato Impresso clique aqui!"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              slotProps={{ tooltip: { sx: { fontSize: '1.5rem', textAlign: 'center' } } }}
            >
              <a href="https://manual.truenumbers.com.br" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar cursos truenumbers', 'Visitar cursos truenumbers ')}>
                <button>CLIQUE AQUI</button>
              </a>
            </Tooltip>
          </div>
          <div className="ebook-image">
            <img src={ebook} alt="Ebook"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ebook;
