import React, { useRef, useState, useEffect } from "react";
import { Form } from '@unform/web';
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup';
import Loader from "react-js-loader";

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import { TextInput, SelectInput } from '../../components/Form';

import Footer from "../../components/PagesComponents/Footer";

import { sendEmailUnsubscribe } from '../../services/api';

import logo from '../../assets/images/logos/logo.png';

import './styles.css';

const selectStyle: React.CSSProperties = {
  width: '100%',
  height: '4rem',
  borderStyle: 'solid',
  background: '#FFFFFF',
  padding: '1rem',
  borderRadius: '0.3rem',
  borderColor: '#bebebe',
}
const inputStyle: React.CSSProperties = {
  width: '100%',
  height: '4rem',  
  background: '#FFFFFF',
  marginBottom: '1.5rem',
  borderStyle: 'solid',
  borderColor: '#bebebe',
  borderRadius: '0.3rem',
  padding: '1rem',
  borderWidth: 'thin'
}
interface FormData {
  email: string
  reason: string,
}

const UnsubscribeForm = () => {
  const formRef = useRef<FormHandles>(null);
  
  const [loader, setLoader] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Formulario Cancelamento Inscricao');

  useEffect(() => {
    gaEventTracker.sendPageView('/cancelar-inscricao', 'Formulario Cancelamento Inscricao');
  }, []);

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      setLoader(true);
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um email válido')
          .required('Campo email é obrigatório'),        
      });
      await schema.validate(data, {
        abortEarly: false,
      });      
      await sendEmailUnsubscribe(data.email, data.reason);  
      formRef.current?.setErrors({});
      reset();
      setLoader(false);     
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;          
          //@ts-ignore
          formRef.current?.clearField(error.path);
        });
        formRef.current?.setErrors(validationErrors);              
      }
      setLoader(false);      
    }   
  };

  const motivos = [
    'Não desejo mais receber esses emails',
    'O conteúdo não me interessa, não desejo mais receber esse tipo de email',
    'Eu nunca dei permissão para receber esses emails, por favor, reporte isso como abuso',   
    'Outro'
  ]

  return (
    <div id="unsubscribe-page" className="container">
      <div className="unsubscribe">
        <div className="unsubscribe-content">
          {
            loader &&
            <div id="loader">
              <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={100} />
            </div> 
          }                 
          <div className="unsubscribe-main">
            <img src={logo} alt="logo"/>            
            <div className="unsubscribe-box">
              <strong>CANCELAR INSCRIÇÃO</strong>
            </div>           
            <div className="form-box">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <p>Email</p>
                <TextInput name="email" placeholder="Seu email" type='email' styles={inputStyle}/>
                <p>Motivo</p>
                <SelectInput 
                  name="reason"                 
                  styles={selectStyle}
                  options={motivos}/>
                <div className="button-box">
                  <button type="submit">ENVIAR</button>
                </div>
              </Form>
            </div> 
          </div>                   
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UnsubscribeForm;
