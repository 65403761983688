import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Header from '../../components/PagesComponents/Header';
import Faq from '../../components/PagesComponents/Faq';
import Footer from '../../components/PagesComponents/Footer';


import { mainStyle } from './styles';

const FaqPage: React.FC = () => {  

  const gaEventTracker = useAnalyticsEventTracker('FAQ Page');
    
  useEffect(() => {
    gaEventTracker.sendPageView('/perguntas-mais-frequentes', 'FAQ Page');    
  }, []);

  return (
    <Box sx={mainStyle}> 
      <Header />
      <Faq />
      <Footer />
    </Box>
  );
}

export default FaqPage;
