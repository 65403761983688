import Box from '@mui/system/Box';

import ILesson from '../../../data/interfaces/ILesson';

import DateUtils from '../../../utils/dateUtils';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  thumbnailBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,  
  linkStyle, 
  notReleasedStyle,
  releasedInStyle,
} from './styles';

interface Props { 
  lesson: ILesson;  
}
type props = Props

const ESCOLA_URL = 'https://aluno.escoladenumerologia.com.br/mentorias/';

const LessonInfo = ({ lesson }: props) => {  
  const dateUtils = DateUtils();  

  return (
    <Box sx={mainStyle}>             
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
         <Box component='h1' sx={h1Style}>{lesson.title}</Box> 
        </Box>
          
        <Box sx={thumbnailBoxStyle}>
          <Box component='img' sx={imgStyle} src={lesson.thumbnail} alt='thumbnail'/>
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{lesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{lesson.description}</Box>
        {
          !lesson.isReleased ?
            (lesson.workload === '' && lesson.value == 0 ) ?
              <Box sx={notReleasedStyle}>
                <Box component='strong' sx={releasedInStyle}>EM BREVE</Box>                
              </Box> 
            :        
              <Box sx={notReleasedStyle}>
                  <Box component='strong' sx={releasedInStyle}>DISPONÍVEL EM {dateUtils.handleDate(lesson.releaseDate)}</Box>
                  <Box component='strong'sx={{...releasedInStyle, color: 'var(--color-text-value)'}}>VALOR: R$ {lesson.value}</Box>
              </Box>           
          :
          <Box sx={buttonBoxStyle}>
            <Box component='a' sx={linkStyle} href={ESCOLA_URL+lesson.uid} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>COMPRE AQUI - VALOR: R$ {lesson.value}</Box>
            </Box>                     
          </Box>
        }
      </Box>
    </Box>
  );
};

export default LessonInfo;
