import React, { useEffect } from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import CoursesList from '../../components/PagesComponents/CoursesList';
import Footer from '../../components/PagesComponents/Footer';
import Header from '../../components/PagesComponents/Header';

import { mainStyle } from './styles';

const CoursesPage: React.FC = () => {
  
  const gaEventTracker = useAnalyticsEventTracker('Pagina Cursos');

  useEffect(() => {
    gaEventTracker.sendPageView('/cursos', 'Pagina Cursos');
  }, []);

  return (
    <Box sx={mainStyle}>     
      <Header />
      <CoursesList />
      <Footer />
    </Box>
  );
}

export default CoursesPage;
