import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string,
  label?: string,
  placeholder?: string,
  maxLength?: number,
  styles?: React.CSSProperties,
}

type InputProps = JSX.IntrinsicElements['textarea'] & Props
const TextAreaInput = ({ name, placeholder, maxLength, styles }: InputProps) => {
  const textAreaRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  
  return (
    <textarea ref={textAreaRef} placeholder={error ? error : placeholder} maxLength={maxLength} style={styles} />
  );
}

export default TextAreaInput;
