import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/system/Box';
import Loader from "react-js-loader";

import Header from '../../components/PagesComponents/Header';
import Footer from '../../components/PagesComponents/Footer';
import CourseInfo from '../../components/PagesComponents/CourseInfo';

import api from '../../services/api';

import ICourse from '../../data/interfaces/ICourse';

import { mainStyle } from './styles';

const CoursePage = () => {
  const [ course, setCourse ] = useState<ICourse|null>(null);  
  
  let { courseUid } = useParams();  

  useEffect(() => {  
    async function loadData() {
      try {
        const response = await api.get('/public/courses/'+courseUid);
        console.log(response)      
        if (response.data) {          
          setCourse(response.data.course);
        }
              
      } catch (error: any) {
                           
      }         
    }    
    loadData();   
  }, []);

  return (
    <Box sx={mainStyle}>     
      <Header />      
      {
        course ?
          <CourseInfo course={course}/>
        :  
          <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={40} />        
      }
      <Footer />
    </Box>
  );
}

export default CoursePage;
