import { useState } from 'react';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'

import ILesson from '../../../data/interfaces/ILesson';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,
  imgStyle,
  infoBoxStyle,
  strongStyle,
  descriptionStyle,
  buttonBoxStyle,
  buttonStyle,
  linkStyle,
  reactPlayer,
} from './styles';

interface Props { 
  lesson: ILesson;
}
type props = Props

const ESCOLAL_URL = 'https://aluno.escoladenumerologia.com.br';

const LessonVideoPlayer = ({ lesson }: props) => {    
  return (
    <Box sx={mainStyle}>       
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>{lesson.title}</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          <ReactPlayer
            style={reactPlayer}
            url={lesson.video}
            width='100%'
            height='100%'
            controls
            pip
            light={<img style={imgStyle} src={lesson.thumbnail} alt='Thumbnail' />}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </Box>
        <Box sx={infoBoxStyle}>
          <Box component='strong' sx={strongStyle}>{lesson.workload}</Box> 
        </Box>
        <Box component='p' sx={descriptionStyle}>{lesson.description}</Box>
        {
          lesson.value > 0 &&
          <Box sx={buttonBoxStyle}>
            <Box component='a' sx={linkStyle} href={ESCOLAL_URL} target='_blank' rel='noreferrer noopener'>
              <Box component='button' sx={buttonStyle} type='button'>ACESSE AQUI</Box>
            </Box>                     
          </Box>
        }        
      </Box>     
    </Box>
  );
};

export default LessonVideoPlayer;
