import React from "react";
import sobre from '../../../assets/images/sobre.png';

import './styles.css';

const About: React.FC = () => {
  return (
    <div className="about">
      <div className="about-content">
        <div className="about-text">
          <strong>SOBRE</strong>
          <p>OLÁ, SEJA BEM-VINDO (A) A INCRíVEL JORNADA EVOLUTIVA DO 
            AUTOCONHECIMENTO E SABEDORIA DIVINA ATRAVÉS DA NUMEROLOGIA.</p>
          <p>CADA VEZ MAIS A NUMEROLOGIA É UTILIZADA POR DIVERSOS 
            PROFISSIONAIS COMO UMA FERRAMENTA NA PRÁTICA DE TERAPIAS 
            HOLÍSTICAS VOLTADAS PARA O DESENVOLVIMENTO PESSOAL E NA 
            BUSCA DO AUTOCONHECIMENTO.</p>
          <p>A ESCOLA DE NUMEROLOGIA É UM ESPAÇO DEDICADO AO APRENDIZADO 
            DA CIÊNCIA NUMEROLÓGICA E AOS PROFISSIONAIS QUE BUSCAM UMA 
            ESPECIALIZAÇÃO E UM DESENVOLVIMENTO MAIS PROFUNDO ATRAVÉS 
            DOS ESTUDOS PRÁTICOS E TÉCNICAS DA INTERPRETAÇÃO DA NUMEROLOGIA.</p>
          <p>O NOSSO OBJETIVO É DAR SUBSÍDIOS E SUPORTE TEÓRICO E PRÁTICO 
            PARA UMA MELHOR COMPREENSÃO SOBRE NUMEROLOGIA, QUE CERTAMENTE 
            SERÁ DE GRANDE AJUDA PARA A SUA JORNADA EVOLUTIVA.</p>
        </div>
      </div>
    </div>
  );
};
export default About;
