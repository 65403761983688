import { useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import ReactPlayer from 'react-player'
import Loader from "react-js-loader";

import api from '../../../services/api';

import {
  mainStyle,
  contentStyle,
  titleBoxStyle,
  h1Style,
  videoBoxStyle,  
  reactPlayer,
  separatorStyle,
} from './styles';

const OurLife = () => {    
  const [ url, setUrl ] = useState<any>(null);

  useEffect(() => {  
    async function loadData() {
      try {
        const payload = {
          filename: 'apoio/numerologia_em_nossa_vida.mp4',
        }
        const response = await api.post('/public/getSignedUrl', payload);         
        if (response.data) {          
          setUrl(response.data.url);          
        }        
      } catch (error: any) {
                          
      }     
    }    
    loadData();    
  }, []);   

  return (
    <Box sx={mainStyle}>         
      <Box sx={contentStyle}>
        <Box sx={titleBoxStyle}>
          <Box component='h1' sx={h1Style}>A NUMEROLOGIA EM NOSSA VIDA</Box> 
        </Box>
        <Box sx={videoBoxStyle}>
          {
            url ?
              <ReactPlayer
              style={reactPlayer}
              url={url}
              width='100%'
              height='100%'
              controls
              pip             
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload'
                  }
                }
                }}
              />
              :
              <Loader type="spinner-circle" bgColor={"#AC6633"} color={'#FFFFFF'} size={50} />
          }
        </Box>                   
      </Box>     
    </Box>
  );
};

export default OurLife;
